import React from "react";
import {
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton
} from "react-share";
import './assets/styles/_index.scss';

const StaticShare = (props) => {

    // Social share
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + shareurl,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    // Social share

    return (
        <>
            <div className="static-social-share-list">
                <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                    <i className='icon icon-fb'></i>
                </FacebookShareButton>
                <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                    <i className='icon icon-twitter'></i>
                </TwitterShareButton>
                {/* <InstapaperShareButton onClick={() => trackerShare('InstaShareButton')} className="my-share-button insta-share">
                    <i className='icon icon-insta'></i>
                </InstapaperShareButton> */}
                <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                    <i className='icon icon-linkedin'></i>
                </LinkedinShareButton>
                <PinterestShareButton onClick={() => trackerShare('PinterestShareButton')} media={props.Image} className="my-share-button pinterest-share">
                    <i className='icon icon-pinterest'></i>
                </PinterestShareButton>
            </div>
        </>
    )
}

export default StaticShare