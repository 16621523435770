import * as React from "react"
import HeaderTwo from './HeaderTwo/HeaderTwo'
import loadable from "@loadable/component"
import "../styles/main.scss";
const Footer = loadable(() => import("./Footer/Footer"))

const LayoutTwo = ({
    children,
    layout,
    popularSearch,
    footerClass,
    popularSearchObject,
    popularSearchDetailsObject,
    area
}) => {

    return (
        <>
            <HeaderTwo
                layout={layout}
            />
            <main>{children}</main>
            <Footer
                footerClass={footerClass}
                popularSearchObject={popularSearchObject}
                popularSearch={popularSearch}
                popularSearchDetailsObject={popularSearchDetailsObject}
                area={area}
            />
        </>
    )
}

export default LayoutTwo