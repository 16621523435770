import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import './assets/styles/_index.scss'

const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const SellLetHome = (props) => {

    const handleClickScroll = () => {
        // const element = document.getElementById('property-desc-wrapper');
        const element = document.querySelector(".career-landing-wrapper");

        if (element) {
            // element.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                behavior: 'smooth',
                top:
                    element.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    100,
            })
        }
    };
    return (
        <section>
            <Container>
                <div className="sell-let-home-wrapper">
                    <Row className="d-flex justify-content-center">
                        <Col xl={7} className="text-center">
                            {props.title && <h2 className="sell-let-home-title">{props.title}</h2>}
                            {props.content && <ContentModule Content={props.content.data.content} />}
                            <ul className="list-inline">
                                {props.cta_1_title && props.cta_1_link && props.cta_1_title !== "View opportunities" && <li className="list-inline-item">
                                    <CTALink class="button button-primary" link={props.cta_1_link} title={props.cta_1_title} target_window={props.cta_1_link.target_window}>
                                        {props.cta_1_link.slug === "property-results" ? <i className="icon icon-banner-search"></i> : <i className="icon icon-banner-valuation"></i>} {props.cta_1_title}
                                    </CTALink>
                                </li>}
                                {props.cta_1_title && props.cta_1_link && props.cta_1_title === "View opportunities" && <li className="list-inline-item">
                                    <a href="javascript:void(0)" onClick={handleClickScroll} className="button button-primary button-with-icon">
                                        {props.cta_1_link.slug === "property-results" ? <i className="icon icon-banner-search"></i> : <i className="icon icon-banner-valuation"></i>} {props.cta_1_title}
                                    </a>
                                </li>}
                                {props.cta_2_title && props.cta_2_link && <li className="list-inline-item">
                                    <CTALink class="button button-primary-light" link={props.cta_2_link} title={props.cta_2_title} target_window={props.cta_2_link.target_window}>
                                        {props.cta_2_link.slug === "property-results" ? <i className="icon icon-banner-search"></i> : <i className="icon icon-envelope"></i>} {props.cta_2_title}
                                    </CTALink>
                                </li>}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default SellLetHome