import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"

import "./assets/styles/_index.scss"
import StaticShare from "../StaticShare/StaticShare"
import GGFXImage from "../GGFX/GGFXImage"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const NewsDetailLanding = props => {
  var imagename = "blog.banner_image.details"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.banner_image_Transforms) {
    processedImages = props?.imagetransforms?.banner_image_Transforms
  }
  return (
    <div className="news-detail-wrapper">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col xl={7}>
            <div className="news-detail-intro">
              <h1>{props.title}</h1>
              <div className="author-and-date-section">
                {props?.author && (
                  <p>
                    By <Link>{props.author.name}</Link>
                  </p>
                )}
                <p>{props.date}</p>
              </div>
            </div>
            <div className="news-detail-content">
              {props?.banner_image && (
                <div className="news-detail-image-wrapper img-wrapper">
                  <GGFXImage
                    ImageSrc={props?.banner_image}
                    altText={
                      props?.banner_image?.alternativeText || props.title
                    }
                    imagetransforms={props.ggfx_results}
                    renderer="srcSet"
                    imagename={imagename}
                    strapiID={props?.strapi_id}
                    className="img-fluid"
                  />
                </div>
              )}
              {props?.blog_content?.length > 0 &&
                props.blog_content?.map((module, index) => {
                  return (
                    <>
                      {module.strapi_component ===
                        "page-modules.plain-content" && (
                        <div className="news-detail-desc-wrapper">
                          <ContentModule
                            Content={module.content?.data?.content}
                          />
                        </div>
                      )}
                      {module.strapi_component === "page-modules.image" && (
                        <div
                          className={`news-detail-image-wrapper img-wrapper first-img-${index}`}
                        >
                          <GGFXImage
                            ImageSrc={module?.image}
                            altText={module?.image?.alternativeText}
                            imagetransforms={props.ggfx_results}
                            renderer="srcSet"
                            imagename={imagename}
                            strapiID={props?.strapi_id}
                            className="img-fluid"
                          />
                        </div>
                      )}
                    </>
                  )
                })}
              <div className="share-section-wrapper">
                <h3>Share this post</h3>
                <div className="share-section">
                  <StaticShare Image={props?.banner_image?.url} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewsDetailLanding
