import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Navbar, Container, Offcanvas, Row, Col } from "react-bootstrap"
import HeaderLogo from "../HeaderLogo/HeaderLogo"
import HeaderMenu from "../HeaderMenu/HeaderMenu"
import BurgerMenu from "../BurgerMenu/BurgerMenu"
import useHasScrolled from "../../hooks/useHasScrolled"
import BurgerMenuList from "../BurgerMenuList/BurgerMenuList"

import LogoImg from "../../images/logo.svg"
import './assets/styles/_index.scss'
import { PageLinks } from "../../common/site/page-static-links"
import SearchOverlayTrigger from "../SearchOverlay/SearchOverlay"
const { useLocation } = require("@reach/router")

const HeaderTwo = (props) => {

    // Scroll
    const scrolled = useHasScrolled()
    // Scroll

    // Burger menu
    const [showBurger, setShowBurger] = useState(false);
    const handleCloseBurger = () => setShowBurger(false);
    const handleShowBurger = () => setShowBurger(true);
    // Burger menu

    // Modal
    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleCloseFilterModal = () => setShowFilterModal(false);
    const handleShowFilterModal = () => setShowFilterModal(true);
    // Modal

    let { pathname } = useLocation()
    useEffect(() => {
        if (pathname.includes("/property/property-for-sale-")) {
            const area = pathname.split("in-")[1].split("-").slice(0, -1).join('-')
            window.location.replace(`/property/for-sale/in-${area}/`)
        }
        if (pathname.includes("/property/property-for-rent-")) {
            const area = pathname.split("in-")[1].split("-").slice(0, -1).join('-')
            window.location.replace(`/property/to-rent/in-${area}/`)
        }
    }, [])
    return (
        <>
            <header className={`header header-two d-flex align-items-center ${scrolled ? "header-scrolled" : ""} ${props.layout}`}>
                <Navbar bg="" expand="xl" fixed="top">
                    <Container>
                        <HeaderLogo
                            headerTwoLogo="headerTwo"
                        />

                        <HeaderMenu
                            headerTwo="header-two"
                            handleShowFilterModal={handleShowFilterModal}
                        />

                        <BurgerMenu
                            headerTwo="header-two"
                            handleShowBurger={handleShowBurger}
                            handleShowFilterModal={handleShowFilterModal}
                        />
                    </Container>
                </Navbar>
            </header>
            <Offcanvas show={showBurger} onHide={handleCloseBurger} placement="end" className="burger-menu-wrapper-offcanvas">
                <Offcanvas.Header closeButton>
                    <div className="w-100">
                        <Row className="d-flex align-items-center">
                            <Col md={6} xs={9}>
                                <Link to="/">
                                    <img src={LogoImg} alt="" className="logo-img" />
                                </Link>
                            </Col>
                            <Col md={6} xs={3}>
                                <ul className="list-inline offcanvas-burger-menu d-flex align-items-center justify-content-end">
                                    <li className="list-inline-item d-md-block d-none">
                                        <Link to={`/${PageLinks.valuation}/`} className="header-cta-btn d-flex align-items-center"><i className="icon icon-header-cta-arrow"></i> <span>Get a valuation</span></Link>
                                    </li>
                                    <li className="list-inline-item d-md-block d-none">
                                        <a href="javascript:;" onClick={() => handleShowFilterModal()} className="header-search-btn d-flex align-items-center justify-content-center"><i className="icon icon-header-search"></i></a>
                                    </li>
                                    <li className="list-inline-item d-md-none">
                                        <a href="javascript:;" onClick={() => handleShowFilterModal()} className="menu-link-sm">
                                            <i className="icon icon-header-search"></i>
                                            <div className="header-text-sm">Search</div>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <BurgerMenuList />
                </Offcanvas.Body>
            </Offcanvas>
            <SearchOverlayTrigger
                handleCloseFilterModal={handleCloseFilterModal}
                showFilterModal={showFilterModal}
            />
        </>
    )
}

export default HeaderTwo