import React from 'react';
import './assets/styles/_index.scss'
import { Link } from 'gatsby';
import { PageLinks } from "../../common/site/page-static-links";
import GGFXImage from '../GGFX/GGFXImage';
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const NewsCard = (props) => {
    let imagename = "blog.tile_image.small_image";
    let processedImages = JSON.stringify({});

    if (props?.imagetransforms?.tile_image_Transforms) {
        processedImages = props?.imagetransforms?.tile_image_Transforms;
    }
    return (
        <div className='news-card-wrapper'>
            <div className='img-wrapper'>
                <Link to={`/${PageLinks.news}/${props.slug}/`}>
                    <GGFXImage
                        ImageSrc={props?.tile_image}
                        altText={props?.tile_image?.alternativeText || props.title}
                        imagetransforms={props.ggfx_results}
                        renderer="srcSet"
                        imagename={imagename}
                        strapiID={props?.strapi_id}
                    />
                </Link>
            </div>
            <div className='news-card-content'>
                <Link to={`/${PageLinks.news}/${props.slug}/`}><h3>{props.title}</h3></Link>
                <p>{props.date}</p>
            </div>
        </div>
    )
}

export default NewsCard