import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import loadable from '@loadable/component'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './assets/styles/_index.scss'

import { sliderSettings2 } from "../SliderSettings/SliderSettings"
import NewsCard from '../NewsCard/NewsCard'
import { graphql, useStaticQuery } from 'gatsby'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const Slider = loadable(() => import("react-slick"))

const NewsSlider = (props) => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            allStrapiBlog(filter: {publish: {eq: true}}, limit: 6, sort: {fields: date, order: DESC}) {
                edges {
                    node {
                        ...BlogFragment
                    }
                }
            }
        }
    `)
    const AllNews = props.tag === "news-detail" ? props?.newsDetail : data?.allStrapiBlog?.edges
    return (
        <>
            {props.showDividerLine && <div className='divider-line'></div>}
            <div className='news-slider-wrapper section-p-80'>
                <Container>
                    <div className='news-slider-content'>
                        {props.tag === "news-detail" ?
                            <>
                                <h5 className={`${props.tag === "news-detail" ? "text-start" : ""}`}>{props.title}</h5>
                                {props.content && <p>{props.content}</p>}
                            </>
                            :
                            <Row className='justify-content-center'>
                                <Col xl={8}>
                                    <ContentModule Content={props?.content?.data?.content} />
                                </Col>
                            </Row>
                        }
                    </div>
                    <Slider className="news-slider" {...sliderSettings2}>
                        {AllNews && AllNews.map(({ node }, i) => {
                            return (
                                <div key={i}>
                                    <NewsCard {...node} />
                                </div>
                            )
                        })}
                    </Slider>
                </Container>
            </div>
        </>
    )
}

export default NewsSlider